.login_card {
  border: 1px solid black;
  width: "fit-content";
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #282828;
}

.login_img {
  display: flex;
  margin: 20px 0px;
}

.login_img img {
  height: 100px;
  margin: auto;
}

.otp_card > div {
  width: 360px;
}

.inpt_otpcls:nth-child(4) {
  margin-right: 0px !important;
}

.btn_css {
  background-color: "#ECAC35" !important;
  width: 200 !important;
  height: 50 !important;
  margin: "auto" !important;
  color: "black" !important;
  font-weight: 600 !important;
}
.bck_arrw_div {
  display: flex;
  justify-content: center;
  position: relative;
  color: white;
}
.bck_arrw {
  position: absolute;
  color: white;
  left: 10px;
  top: 18px;
}
.otp_inpt {
  display: flex;
  justify-content: center;
}
