.top_bar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.table_icon svg {
  height: 30px !important;
  /* width: 30px !important;
  margin: 10px;
  margin-top: 15px; */
}
