.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-layout {
  height: 100%;
  min-height: 100vh;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  flex: 0 0 280px !important;
  max-width: 270px !important;
  min-width: 270px !important;
  width: 280px !important;
}

.coin_stat_card {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 50px 0px;
}

.coin_stat_card .card_icon {
  font-size: 40px;
  position: absolute;
  bottom: 80px;
  left: 0px;
  right: 0px;
}

.coin_stat_card .amount {
  font-size: 24px;
  font-weight: 500;
}

.stat_card {
  min-height: 230px;
  height: fit-content;
  width: 24%;
  text-align: center;
}

.stat_card .card_heading1 {
  font-size: 18px;
  font-weight: 700;
}

.stat_card .card_heading2 {
  font-size: 18px;
  font-weight: 500;
}

.stat_card .card_icon {
  /* display: flex; */
  align-items: center;
  justify-content: space-around;
  width: 100%;
  font-size: 60px;
}

.breadcrumb_item {
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px;
}

.breadcrumb_item:hover {
  background: rgb(215, 211, 211);
  scale: 1.02;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.phn_cs input {
  height: 55px;
}

h4 {
  color: #011529;
}

h5 {
  font-size: 14px;
  font-weight: 400;
  color: grey;
}

.ant-select-selector {
  height: 55px !important;
  display: flex;
  align-items: center;
}

.ant-select-arrow {
  top: 40% !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: #f7f4f4 !important;
  box-shadow: none !important;
}

.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0px 20px 20px 20px !important;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
  background-color: white;
  width: 400px !important;
  max-height: 220px !important;
  overflow-y: scroll;
  border-radius: 0px !important;
  height: 600px !important;
  max-height: 250px !important;

  @media (max-width: 1280px) {
    width: 300px !important;
  }

  @media (max-width: 992px) {
    width: 230px !important;
  }
}

.react-tel-input .form-control {
  font-size: 16px;
  /* background: #f7f4f4 !important; */
  border: 1px solid black;
  /* border-radius: 5px; */
  width: 100% !important;
  outline: none;
  padding: 18.5px 14px 18.5px 60px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  /* color: #495057; */
  /* border: none !important; */
  border-radius: 10px !important;
  margin-bottom: 10px !important;
  display: flex !important;
  height: 55px !important;
  /* &:focus {
    border-color: "red" !important;
    box-shadow: none !important;
  } */
}

.react-tel-input .country-list .search {
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: red;
  padding: 10px 0 6px 10px;
  width: 100%;
}

.react-tel-input .country-list .search-box {
  /* border: 1px solid $primaryClr !important; */
  border-radius: 10px;
  font-size: 15px;
  line-height: 15px;
  margin-left: 6px;
  padding: 7px 11px 9px !important;
  outline: none;
  width: 94%;
  box-sizing: border-box;
  /* &::placeholder {
    text-transform: capitalize;
  } */
}

.srch {
  display: grid;
  gap: 25px;
}

.table_icon svg {
  margin-top: 8px;
}

span.coin_name {
  margin-left: 8px;
  color: grey;
  font-weight: 500;
}

span.coin_nme {
  padding-left: 20px;
  align-self: center;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: grey;
  /* Change the border color of the checked checkbox */
}

.ant-layout-sider-children {
  overflow-y: scroll;
  /* padding-left: 20px; */
  width: 270px;
}

::-webkit-scrollbar {
  width: 3px;
}

.buton {
  margin: 10px;
  width: 140px;
  border-width: 1px;
  border-color: #192e81;
}

.buton:hover {
  background-color: #ecac35;
  color: #fff !important;
}

.tableCol {
  font-size: 12px;
}

.chart_card .ant-select-selector {
  width: 100%;
  height: 40px !important;
  padding: 0 11px;
  display: flex;
  align-items: center;
  outline: none;
}

.ant-select-arrow {
  top: 50% !important;
}

.content_header {
  margin-bottom: 20px !important;
}

.content_header>div {
  /* margin: 20px 0px !important; */
  /* background-color: pink !important; */
  padding: 20px 20px !important;
  border-radius: 8px !important;
  /* height: 75px; */
}

.cdddddd .ant-select-arrow {
  position: absolute;
  top: 40% !important;
}

.ant-upload-list-item-container {
  display: none;
}

.edt_prlimg {
  margin-top: 15px;
  width: 140px;
  height: 140px;
  cursor: pointer;
  border-radius: 10%;
  /* object-fit: contain; */
}

.img_profile {
  margin-top: 15px;
  width: 140px;
  height: 140px;
  cursor: pointer;
  border-radius: 100%;
}

.img_contnr {
  width: 150px;
  height: 150px;
  border: 1px solid #80808052;
  border-radius: 10px;
  position: relative;
  margin: 0 8px 16px;
}

.img_contnr figure {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  padding: 10px;
  margin: 0;
}

.img_contnr img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img_contnr video {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img_contnr2 {
  width: 60% !important;
}

.ant-form-item-control-input-content h4 {
  margin-top: 0;
  margin-bottom: 10px;
}

.ant-btn.ant-btn-primary {
  background: #fea500 !important;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.ant-btn.ant-btn-primary span {
  line-height: 1;
}

.ant-select-single.select_type.withInput {
  max-width: 200px;
  width: 100%;
}

.ant-select-single.select_type.withInput .ant-select-selector {
  height: 50px !important;
  border-radius: 10px;
}

.ant-select-single.select_type.withInput_small {
  max-width: 120px;
  min-width: 120px;
  text-align: left;
  width: 100%;
}

.ant-select-single.select_type.withInput_small .ant-select-selector {
  height: 40px !important;
}

.ant-switch.ant-switch-checked {
  background: #fea500;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fea500;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #fea500;
}

.ant-pagination .ant-pagination-item-active a {
  color: #fea500;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #fea500;
}

.ant-pagination .ant-pagination-item-active:hover a {
  color: #fea500;
}

.ant-pagination .ant-pagination-item-active:hover {
  border-color: #fea500;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input:hover {
  border-color: #fea500;
}

.ant-btn-default:not(:disabled):hover {
  color: #fea500;
  border-color: #fea500;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #fea500;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #fea500;
  box-shadow: none;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #fea500;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #fea500;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fea500;
  border-color: #fea500;
}

.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: #fea500;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: #fea500;
}

.ant-input::placeholder {
  color: #000;
  opacity: 0.5;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #fea500;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #fea500;
  box-shadow: none;
}

.error_msg {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #ff4d4f;
}

.select_type2 {
  border: 1px solid #ff4d4f;
  border-radius: 6px;
}

#basic_otp_help>div {
  text-align: right !important;
}
.btn-style{
    width: 200px;
    height: 50px;
    margin: auto;
}

@media (max-width: 1024px) {
  .img_profile {
    width: 110px;
    height: 110px;
  }
  .btn-style {
    width: 150px;
    height: 40px;
  }
}

@media (max-width: 1200px) {
  .stat_card {
    width: 48%;
  }
}

.ant-table, .table-container {
  overflow-x: auto!important;
}
@media (max-width:992px) {
  aside.ant-layout-sider.ant-layout-sider-dark{
    display:none;
  }
  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed ul.ant-menu {
    width: 80px;
    transition: 0.3s;
  }
  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed .ant-layout-sider-children {
    width: auto;
    transition: 0.3s;
  }
  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
    transition: 0.3s;
    display:block;
  }
  .stat_card .card_icon{
    font-size:26px;
  }
  .stat_card .ant-card-body{
    padding:10px;
  }
  .ant-layout-content {
    padding: 20px 10px !important;
  }
  .ant-table th,.ant-card-body{
    padding:10px !important;
  }
  .stat_card {
    min-height:unset;
  }
  .tblgrph{
    margin-top:15px;
  }
  .hdng h1 {
    font-size: 22px;
  }
  .srch .ant-table-cell div{
    white-space: nowrap;
    padding: 0px !important;
  }
  .tckt_dtl_mn th{
    white-space:nowrap;
  }
  .srch td.ant-table-cell{
    padding:10px;
  }
  .srch .ant-table-cell{
    padding:10px;
  }
  .stat_card .card_heading1 {
    font-size: 16px;
  }
  .stat_card .card_heading2 {
    font-size:14px;
  }
  .erngtbl h2,.tblgrph h2{
    font-size:16px !important;
  }
  .tckt_dtl_mn ,.cstmr_dtls{
    margin: 15px 0;
  }
  .evtsdtl_mn{
    padding:50px 20px !important;
    margin:10px 0 !important;
  }
  .evtsdtl_mn h2,.evtsdtl_mn h4,.evtsdtl_mn h5{
    margin:0 !important;
  }
  .mngusrs 
  .evntrw,.eventrules{
    margin-top:15px;
  }
  .evntrw .ant-col {
    max-width: 30% !important;
  }
  .ntfctn_mn .ant-table-cell,.mngerngs_mn th,.mngerngs_mn td,.mngfundrsng_mn th,.mngfundrsng_mn td,.mngevent th,.mngevent td{
    padding:10px !important;
    white-space:nowrap;
  }
  .mngerngs_mn .ant-tabs-nav{
    margin-bottom:0;
  }
  .adfndrsng h3{
    margin-top:0 !important;
  }
  .mngevent,.events{
    display:block !important;
  }
  .srchevnt .ant-row {
    gap: 10px;
    padding-bottom: 30px !important;
  }
  .mngusrdetails .ant-col{
    margin-top:8px;
  }
  .gap_prprty{
    flex-wrap:wrap !important;
  }
  .mngerngs_mn .ant-col{
    flex-wrap:wrap !important;
    margin-bottom:20px !important;
    gap:25px !important;
  }
}
@media (max-width:600px) {
  .stat_card {
    width: 100%;
    min-height:unset;
  }
  input,.ant-picker,.ant-select-selector{
    height:40px !important;
  }
  .evntrw .ant-col {
    max-width: 48% !important;
  }
  .evtsdtl_mn h2{
    font-size:17px;
  }
  .entbkngdtals .ant-col {
    margin-top: 5px;
  }
  .content_header>div{
    padding:10px !important;
  }
  .login_card{
    display:block !important;
    width:100%;
  }
  .login_card button{
    width: 170px !important;
    height: 45px !important;
  }
  .login_card input,.login_card .ant-input-affix-wrapper{
    width:100% !important;
  }
  .bck_arrw_div h3{
    margin:0;
  }
  .login_card form{
      margin-top:0 !important;
  }
  .login_card .ant-form-item{
    margin-bottom:10px;
  }
  #basic_remember{
    width: 100% !important;
    flex-wrap: wrap !important;
    gap: 10px !important;
  }
  #basic_phone {
    width: 100% !important;
    height: 40px !important;
    margin-bottom: 20px;
  }
  .login_card .ant-col {
    margin: 0;
    max-width:100% !important;
  }
  .otp_inpt{
    width:100% !important; 
  }
  .hdr_mn{
    padding:10px 5px 10px 20px !important;
  }
  #basic{
    min-width:100% !important;
    max-width:100% !important;
  }
  .documents .ant-col {
    margin:0 !important;
  }
}
@media (max-width:480px) {
  .evntrw .ant-col {
    max-width: 100% !important;
  }
  .profilestup,.edteventform{
    overflow:hidden;
  }
  .profilestup #basic{
    max-width:100% !important;
  }

}