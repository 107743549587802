#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: pink;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.logo_img {
    height: 70px;
    margin: auto;
    width: 200px;
    padding-top: 20px;
}

.logo_img_small {
    height: 50px;
    margin: auto;
    margin-top: 10px;
}

.logo {
    display: flex;
}

.auth_wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 15px;
    /* background: linear-gradient(45deg, #1A1A1A, #111111, #282828); */
}

ul.ant-menu.ant-menu-dark.ant-menu-inline.ant-menu-root.css-dev-only-do-not-override-htwhyh.side_menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 22px;
    background-color: #111111 !important;
}

span.ant-menu-title-content {
    font-size: 14px;
}

.side_menu svg {
    font-size: 20px;
}

.ant-menu-dark .ant-menu-item-selected {
    background-color: #FEA500 !important;
    color: #fff;
    font-weight: 600;

}

.ant-layout .ant-layout-sider-children {
    background-color: #111111;
}

/* .side_menu .ant-menu-item-selected {
    background-color: #019B69;
} */

.side_menu .ant-menu-item-active {
    background-color: #FEA500 !important;
}

.ant-menu-dark {
    background-color: #111111 !important;
}

.login_card .ant-card-body {
    background-image: url("/public/static/images/auth-layer.svg");
    background-repeat: no-repeat;
    background-position: top right;
    padding: 30px;
    border-radius: 0;
}

.login_card {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    box-shadow: 0 0 40px -5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.bck_arrw_div {
    color: #000;
}

.auth_wrapper div[style*="cursor"] {
    transition: 0.3s;
}

.auth_wrapper div[style*="cursor"]:hover {
    color: #FEA500;
}

.otp_inpt .inpt_otpcls {
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    width: 40px !important;
    height: 40px !important;
    outline: none;
}

.otp_inpt .inpt_otpcls:focus {
    border: 1px solid #FEA500;
}